$(document).ready(function() {
    "use strict";

    /*==================================

* Author        : "ThemeSine"

* Template Name : Travel HTML Template

* Version       : 1.0

==================================== */

    /*=========== TABLE OF CONTENTS ===========

1. Scroll To Top
2. Range js
3. Countdown timer
5. datepicker
6. Smooth Scroll spy
7. Animation support
8. Accordion
9. Buttons
======================================*/

    // 1. Scroll To Top

    $(window).on("scroll", function() {
        if ($(this).scrollTop() > 600) {
            $(".return-to-top").fadeIn();
        } else {
            $(".return-to-top").fadeOut();
        }
    });

    $(".return-to-top").on("click", function() {
        $("html, body").animate(
            {
                scrollTop: 0
            },
            1500
        );

        return false;
    });

    // 2. range js

    $("#slider-range").slider({
        range: true,
        min: 0,
        max: 12000,
        values: [2677, 9241],
        slide: function(event, ui) {
            $("#amount").val("$" + ui.values[0] + " - $" + ui.values[1]);
        }
    });
    $("#amount").val(
        "$" +
            $("#slider-range").slider("values", 0) +
            " - $" +
            $("#slider-range").slider("values", 1)
    );

    // Quantity Buttons Shop

    $(".qtyplus").on("click", function() {
        var b = $(this)
                .parents(".quantity-form")
                .find("input.qty"),
            c = parseInt(b.val(), 10) + 1,
            d = parseInt(b.attr("max"), 10);
        d || (d = 9999999999), c <= d && (b.val(c), b.change());
    });
    $(".qtyminus").on("click", function() {
        var b = $(this)
                .parents(".quantity-form")
                .find("input.qty"),
            c = parseInt(b.val(), 10) - 1,
            d = parseInt(b.attr("min"), 10);
        d || (d = 1), c >= d && (b.val(c), b.change());
    });

    // 3.Countdown timer

    function makeTimer() {
        var timer = $("#timer").data("timer");
        var endTime = new Date(timer + ", 12:00:00 PDT");
        var endTime = Date.parse(endTime) / 1000;

        var now = new Date();
        var now = Date.parse(now) / 1000;

        var timeLeft = endTime - now;

        var days = Math.floor(timeLeft / 86400);
        var hours = Math.floor((timeLeft - days * 86400) / 3600);
        var minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
        var seconds = Math.floor(
            timeLeft - days * 86400 - hours * 3600 - minutes * 60
        );

        if (hours < "10") {
            hours = "0" + hours;
        }
        if (minutes < "10") {
            minutes = "0" + minutes;
        }
        if (seconds < "10") {
            seconds = "0" + seconds;
        }

        $("#days").html(days + '<span class="camp">Days</span>');
        $("#hours").html(hours + '<span class="camp">Hour</span>');
        $("#minutes").html(minutes + '<span class="camp">Minute</span>');
        $("#seconds").html(seconds + '<span class="camp">Second</span>');
    }

    setInterval(function() {
        makeTimer();
    }, 1000);

    // 5. datepicker
    $('[data-toggle="datepicker"]').datepicker();

    // 6. Smooth Scroll spy

    $(".header-area").sticky({
        topSpacing: 0
    });

    //=============

    $("li.smooth-menu a").bind("click", function(event) {
        event.preventDefault();
        var anchor = $(this);
        $("html, body")
            .stop()
            .animate(
                {
                    scrollTop: $(anchor.attr("href")).offset().top - -1
                },
                1200,
                "easeInOutExpo"
            );
    });

    $("body").scrollspy({
        target: ".navbar-collapse",
        offset: 0
    });

    // 7.animation support

    $(window).on("load", function() {
        $(".about-us-txt h2")
            .removeClass("animated fadeInUp")
            .css({ opacity: "0" });
        $(".about-us-txt button")
            .removeClass("animated fadeInDown")
            .css({ opacity: "0" });
    });

    $(window).on("load", function() {
        $(".about-us-txt h2")
            .addClass("animated fadeInUp")
            .css({ opacity: "0" });
        $(".about-us-txt button")
            .addClass("animated fadeInDown")
            .css({ opacity: "0" });
    });

    // 8. Accordion

    $("#accordion-structure").accordionjs({
        activeIndex: false,
        slideSpeed: 150
    });

    // 9. Buttons

    // Modal Discount
    $("#button-discount").on("click", function() {
        document.getElementById("modal-discount").style.display = "block";
    });

    $("#button-discount-close").on("click", function() {
        document.getElementById("modal-discount").style.display = "none";
    });

    // Load Facebook SDK for JavaScript

    window.fbAsyncInit = function() {
        FB.init({
            xfbml: true,
            version: "v8.0"
        });
    };

    (function(d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/ru_RU/sdk/xfbml.customerchat.js";
        fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
});
