/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

import "filterizr/dist/filterizr.min";
import "jquery.easing/jquery.easing.min";
import "jquery-ui/ui/widgets/datepicker.js";
import "jquery-ui/ui/widgets/slider";
import "jquery-waypoints/waypoints.min";
import "jquery-sticky/jquery.sticky";
import "@chenfengyuan/datepicker/dist/datepicker";

require("./frontend/index");

window.Vue = require("vue");

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import LanguageSwitcher from "./components/LanguageSwitcher";

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app",
    components: {
        LanguageSwitcher
    }
});
